import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { ApiService } from "app/core/services/api.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private apiservice: ApiService) { }
  
  notifiGetCall(data): Observable<any> {
    return this.apiservice.get(`notification/list`,data)
  }
  
  markAllRead(values): Observable<any> {
    return this.apiservice.post(`update/notification`,values)
  }
}
