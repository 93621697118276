import { IMenuItem } from "./navigation.model";

export let ClubInnMenu: IMenuItem[] = [
  {
    module: ["dashboard"],
    name: "Dashboard",
    type: "link",
    state: "dashboard",
    icon: "dashboard",
  },

  {
    module: ["customers"],
    name: "Customers",
    type: "link",
    state: "customers",
    icon: "supervised_user_circle",
  },
  {
    module: ["places"],
    name: "Places",
    type: "link",
    state: "places",
    icon: " location_on",
  },
  {
    module: ["artists"],
    name: "Artists",
    type: "link",
    state: "artists",
    icon: "recent_actors",
  },
  {
    module: ["events"],
    name: "Events",
    type: "link",
    state: "events",
    icon: "event",
  },
  {
    module: ["bookings"],
    name: "Bookings",
    type: "link",
    state: "bookings",
    icon: "list_alt",
  },
  {
    module: ["commissions"],
    name: "Commissions",
    type: "link",
    state: "commissions",
    icon: "attach_money",
  },
  {
    module: ["payment_transactions"],
    name: "Payment Transactions",
    type: "link",
    state: "payment-transactions",
    icon: "payment",
  },

  {
    module: ["refund"],
    name: "Refund",
    type: "link",
    state: "refund",
    icon: "repeat",
  },
  {
    module: ["banners"],
    name: "Banners",
    type: "link",
    state: "banner",
    icon: "perm_media",
  },
  {
    module: ["notification-admin"],
    name: "Notifications",
    type: "link",
    state: "notification-admin",
    icon: "notifications",
  },
  {
    module: ["reports"],
    name: "Reports",
    type: "dropDown",
    state: "",
    icon: "report",
    sub: [
      {
        name: "Event Reports",
        type: "link",
        state: "report/events",
      },
      {
        name: "Booking Reports",
        type: "link",
        state: "report/bookings",
      },
      {
        name: "Commission Reports",
        type: "link",
        state: "report/commissions",
      },
      {
        name: "Customer Reports",
        type: "link",
        state: "report/customers",
      },
      {
        name: "Payment Transaction Reports",
        type: "link",
        state: "reports/payment-transaction-report",
      },
      {
        name: "Refund Reports",
        type: "link",
        state: "report/refund",
      },
    ],
  },
  {
    module: ["Ticket Verify"],
    name: "Ticket Verify",
    type: "link",
    state: "admin/ticket/verify",
    icon: "qr_code",
  },
  {
    module: ["Master Settings"],
    name: "Master Settings",
    type: "dropDown",
    state: "",
    icon: "settings",
    sub: [
      {
        name: "Venue Owner",
        type: "link",
        state: "venue-owners",
      },
      {
        name: "Ticket Category",
        type: "link",
        state: "ticket-category",
      },
      {
        name: "Handling Fee",
        type: "link",
        state: "service-tax",
      },
      {
        name: "Commission Charges",
        type: "link",
        state: "commission-charge",
      },
      {
        name: "Ratings & Reviews",
        type: "link",
        state: "ratings-reviews",
      },
      {
        name: "Terms & Conditions",
        type: "link",
        state: "terms-conditions",
      },
      {
        name: "Change Password",
        type: "link",
        state: "change-password",
      },
    ],
  },
];

export let ClubInnVenueMenu: IMenuItem[] = [
  {
    module: ["dashboard"],
    name: "Dashboard",
    type: "link",
    state: "venue-owner/dashboard",
    icon: "dashboard",
  },
  {
    module: ["events"],
    name: "Events",
    type: "link",
    state: "venue-owner/events",
    icon: "event",
  },
  {
    module: ["bookings"],
    name: "Bookings",
    type: "link",
    state: "venue-owner/bookings",
    icon: "list_alt",
  },
  {
    module: ["reports"],
    name: "Reports",
    type: "dropDown",
    state: "",
    icon: "report",
    sub: [
      {
        name: "Event Reports",
        type: "link",
        state: "venue-owner/report/events",
      },
      {
        name: "Booking Reports",
        type: "link",
        state: "venue-owner/report/booking",
      },
      {
        name: "Payment Transaction Reports",
        type: "link",
        state: "venue-owner/report/payment-transaction-report",
      },
    ],
  },
  {
    module: ["Ticket Verify"],
    name: "Ticket Verify",
    type: "link",
    state: "venue-owner/ticket/verify",
    icon: "qr_code",
  },
];
