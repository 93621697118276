import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public subject = new Subject<any>();
  constructor() { }

  sendClick() {
    this.subject.next();
  }
  
  getClick():Observable<any> {
    return this.subject.asObservable();
  }
  
  
}
