import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public snackBar: MatSnackBar) {

  }

  present(message: string, action?: string, position?: string) {

    var option = {
      duration: 2000,
     
    };

    var posKey = '';
  
    position = position ??  "top";

    if (position == 'center') {
      posKey = 'horizontalPosition';
    } else if (position == "top") {
      posKey = 'verticalPosition';
    } else if (position == 'bottom') {
      posKey = 'verticalPosition';
    }

    Object.defineProperty(option, posKey, {
      value: position
      
    });

    // option[posKey] = position;
   

    this.snackBar.open(message, action, option);
  }

  showSuccessToast(message) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      text: `${message}`,
      icon: 'success',
      customClass: {
        popup: 'Success-popup-class',
      }
    });
  }
  showErrorToast(message) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      // title: 'Success!',
      text: `${message}`,
      icon: 'error',
      customClass: {
        popup: 'Error-popup-class',
      }
    });
  }
  showWarningToast(message) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      text: `${message}`,
      icon: 'warning',
      customClass: {
        popup: 'warning-popup-class',
      }
    });
  }


}
