import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RelativeTimePipe } from './relative-time.pipe';
import { ExcerptPipe } from "./excerpt.pipe";
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import { AppDisplayDatePipe, AppDisplayTimePipe, AppServerDatePipe, SafePipe } from './app-config.pipe';
import { FileSizePipe } from './filesize.pipe';

const pipes = [
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  SafePipe,
  AppServerDatePipe,
  AppDisplayDatePipe,
  AppDisplayTimePipe,
  FileSizePipe
]

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule {}