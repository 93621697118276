// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

import { config } from "config";

export const environment = {
  production: false,
  apiURL: config.adminapiUrl,
  venueapiURL: config.venueapiUrl,
  ticketVerifyUrl: config.ticketVerifyUrl,
  firebaseConfig : {
    apiKey: "AIzaSyBZp4dwfFDkNB1W-TFj1GEsAGJkIPADFn4",
    authDomain: "club-inn.firebaseapp.com",
    projectId: "club-inn",
    storageBucket: "club-inn.appspot.com",
    messagingSenderId: "108034903930",
    appId: "1:108034903930:web:69f3a47e4fe1e43424d0c3",
    measurementId: "G-NJNSEJ84L1"
  }
};
