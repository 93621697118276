import { Injectable } from '@angular/core';
import { VenueApiService } from 'app/core/services/venueownerapi.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VenuenotificationService {

  constructor(private venueApiService: VenueApiService) { }
 


notifiGetCall(data): Observable < any > {
  return this.venueApiService.get(`venue/notification/list`, data)
}

markAllRead(values): Observable < any > {
  return this.venueApiService.post(`venue/update/notification`, values)
}
}
